<template>
  <HeartysanListingCreateBlock
      :HL="HL"
      @save="save"
  />
</template>

<script>
import HeartysanListingCreateBlock
  from "@/components/modules/HeartysanModule/components/HeartysanManufacturer/HeartysanListingCreate/HeartysanListingCreateBlock/HeartysanListingCreateBlock";
import {HeartysanListing} from "@/components/modules/HeartysanModule/models/HeartysanListing";
import {heartysanProductsMixin} from "@/mixins/heartysanMixins/heartysanProductsMixin";
export default {
  name: "HeartysanListingCreate",

  components: {HeartysanListingCreateBlock},

  mixins: [heartysanProductsMixin],

  data() {
    return {
      HL: new HeartysanListing(),
    }
  },

  mounted() {

  },

  methods: {

  },

}
</script>

<style scoped>

</style>