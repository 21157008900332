<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['menu_heartysanListingsCreate',])"></div>
      <CardLeftBlock
          :name="''"
          :value="''"
          :backgroundImage="'product-admin'"
      >
        <template slot="button">
          <h2 class="mt-0">{{$t('menu_heartysanListingsCreate.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
<!--            {{ new Date() | moment("DD MMM, YYYY") }}-->
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">

            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['heartysan_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.heartysanManufacturerListings" class="order-create__head-back">
                  <LinkBack
                      :value="$t('heartysan_backTo.localization_value.value')"
                      @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['heartysan_CreateListing',])"></div>
              {{$t('heartysan_CreateListing.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['heartysan_CreateListingPleaseFill',])"></div>
              {{$t('heartysan_CreateListingPleaseFill.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <ManufacturerListingFields
                :HL="HL"
            />


          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
              <router-link :to="$store.getters.GET_PATHS.heartysanManufacturerListings"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create',])"></div>

              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="$emit('save', false, 'open')"
                  v-bind:class="{'disabled-btn' : $store.getters.getFillClaimBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import LinkBack from "@/components/UI/buttons/LinkBack/LinkBack";
import CardLeftBlock from "@/components/coreComponents/CardLeftBlock/CardLeftBlock";
import CardRightBlock from "@/components/coreComponents/CardRightBlock/CardRightBlock";
import ManufacturerListingFields
  from "@/components/modules/HeartysanModule/components/chunks/ManufacturerListingFields/ManufacturerListingFields";
export default {
  name: "HeartysanCreateListingBlock",
  components: {ManufacturerListingFields, CardRightBlock, CardLeftBlock, LinkBack, MainButton},

  props: {
    HL: Object,
  }

}
</script>

<style scoped>

</style>